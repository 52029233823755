
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Menu from "./menu.vue";
import { asyncRoutes } from "../router/config";
import _ from "lodash";
import userDrop from "./user-drop";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import Breadcrumb from "../store/modules/breadcrumb";
import { RouteLocationNormalized, RouteRecordName } from "vue-router";

@Options({
  name: "Layout",
  components: {
    Menu,
    userDrop,
  },
})
export default class Layout extends Vue {
  public menu: Array<any> = asyncRoutes;
  public selectedKeys: (RouteRecordName | null | undefined)[] = [];
  public openKeys: (RouteRecordName | null | undefined)[] = [];

  get routerMatched() {
    const breadcrumb = getModule(Breadcrumb, store);
    return breadcrumb.matched;
  }

  get pageName() {
    const length = this.routerMatched.length;
    return this.routerMatched[length - 1].meta?.title;
  }

  created() {
    const route: RouteLocationNormalized = this.$route;
    const matchedLength = route.matched.length;
    this.openKeys = route.matched
      .slice(0, matchedLength - 1)
      .map((item) => item.name);
    this.selectedKeys = [this.$route.name];
  }

  public onOpenChange(openKeys: string[]) {
    if (openKeys.length > 1) {
      this.openKeys = openKeys.splice(openKeys.length - 1, 1);
    }
  }
  public returnHome() {
    this.$router.push('/home')
  }

}
